var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',[_c('h2',[_vm._v("Vertex Unit Test Results")])]),_c('CCol',{staticClass:"text-right"},[_c('CSelect',{attrs:{"options":_vm.showChoices,"value":_vm.showChoice,"label":"Show Tests:","horizontal":""},on:{"update:value":function($event){_vm.showChoice=$event}}})],1),_c('CCol',{staticClass:"text-right"},[_c('DownloadLink',{staticClass:"btn btn-primary",attrs:{"title":"Download","url":("/api/vtx/clients/" + _vm.clientCode + "/test-results/" + _vm.testRunId + "/download")}},[_c('MIcon',{attrs:{"name":"fa-download"}}),_vm._v(" Export ")],1)],1)],1),(_vm.testResultSummary.total > 0)?_c('CRow',[_c('CCol',[_c('CCallout',{staticClass:"pointer",class:{ 'de-selected': _vm.showChoice !== 'ALL' },attrs:{"color":"info"},nativeOn:{"click":function($event){_vm.showChoice = 'ALL'}}},[_c('small',{staticClass:"h5"},[_vm._v("Total")]),_c('br'),_c('strong',{staticClass:"h4"},[_vm._v(_vm._s(_vm.testResultSummary.total))])])],1),_c('CCol',[_c('CCallout',{staticClass:"pointer",class:{
              'de-selected': _vm.showChoice !== 'ALL' && _vm.showChoice !== 'VALID',
            },attrs:{"color":"success"},nativeOn:{"click":function($event){_vm.showChoice = 'VALID'}}},[_c('small',{staticClass:"h5"},[_vm._v("Passed")]),_c('br'),_c('strong',{staticClass:"h4"},[_vm._v(_vm._s(_vm.testResultSummary.passed))]),(_vm.testResultSummary.passedPct > 0)?_c('span',{staticClass:"h5 text-muted pl-2"},[_vm._v("("+_vm._s(_vm.$format.percent(_vm.testResultSummary.passedPct))+")")]):_vm._e()])],1),_c('CCol',[_c('CCallout',{staticClass:"pointer",class:{
              'de-selected': _vm.showChoice !== 'ALL' && _vm.showChoice !== 'INVALID',
            },attrs:{"color":"warning"},nativeOn:{"click":function($event){_vm.showChoice = 'INVALID'}}},[_c('small',{staticClass:"h5"},[_vm._v("Failed")]),_c('br'),_c('strong',{staticClass:"h4"},[_vm._v(_vm._s(_vm.testResultSummary.invalid))]),(_vm.testResultSummary.invalidPct > 0)?_c('span',{staticClass:"h5 text-muted pl-2"},[_vm._v("("+_vm._s(_vm.$format.percent(_vm.testResultSummary.invalidPct))+")")]):_vm._e()])],1),_c('CCol',[_c('CCallout',{staticClass:"pointer",class:{
              'de-selected': _vm.showChoice !== 'ALL' && _vm.showChoice !== 'FAILED',
            },attrs:{"color":"danger"},nativeOn:{"click":function($event){_vm.showChoice = 'FAILED'}}},[_c('small',{staticClass:"h5"},[_vm._v("Errors")]),_c('br'),_c('strong',{staticClass:"h4"},[_vm._v(_vm._s(_vm.testResultSummary.errors))]),(_vm.testResultSummary.errorPct > 0)?_c('span',{staticClass:"h5 text-muted pl-2"},[_vm._v("("+_vm._s(_vm.$format.percent(_vm.testResultSummary.errorPct))+")")]):_vm._e()])],1),_c('CCol',{staticClass:"text-right",attrs:{"col":"8"}},[_c('CButtonToolbar',[_c('CButton',{on:{"click":_vm.exportResults}},[_vm._v("Export")])],1)],1)],1):_vm._e()],1),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.currentItems,"fields":_vm.currentFields,"loading":_vm.searchTestsActive,"striped":"","pagination":"","size":"sm","items-per-page":50,"sorter":"","column-filter":"","table-filter":""},scopedSlots:_vm._u([{key:"show_details",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleDetails(item, index)}}},[_vm._v(_vm._s(Boolean(item._toggled) ? 'Hide' : 'Show'))])],1)]}},{key:"details",fn:function(ref){
            var item = ref.item;
return [_c('CCollapse',{attrs:{"show":Boolean(item._toggled),"duration":_vm.collapseDuration}},[_c('CRow',[_c('CCol',[_c('div',{staticStyle:{"display":"flex"}},[_c('div',[_c('CButton',{attrs:{"color":"info"},on:{"click":function($event){return _vm.handleShowInfo(item)}}},[_c('MIcon',{attrs:{"name":"fa fa-search-plus"}})],1)],1),_c('div',[_c('ul',_vm._l((item.messages),function(msg){return _c('li',[_vm._v(_vm._s(msg))])}),0)])])])],1)],1)]}},{key:"status",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('span',{class:_vm.getStatusObject(item).variant
                  ? 'text-' + _vm.getStatusObject(item).variant
                  : ''},[_c('MIcon',{attrs:{"name":_vm.getStatusObject(item).status}})],1)])]}},{key:"clientCode",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{ name: 'view-client', params: { name: item.clientCode } }}},[_vm._v(_vm._s(item.clientCode))])],1)]}},{key:"title",fn:function(ref){
                  var item = ref.item;
return [_c('td',[(item.taskId)?_c('router-link',{attrs:{"to":{ name: 'view-task', params: { id: item.taskId } }}},[_vm._v(_vm._s(item.title))]):_c('div',[_vm._v(_vm._s(item.title))])],1)]}},{key:"recordStatus",fn:function(ref){
                  var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getStatusColor(item)}},[_vm._v(_vm._s(_vm.getStatusLabel(item)))])],1)]}}])})],1)],1),_c('TestResultModal',{attrs:{"test-result":_vm.currentTestResult},model:{value:(_vm.testResultModal),callback:function ($$v) {_vm.testResultModal=$$v},expression:"testResultModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }