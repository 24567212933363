<template>
  <div>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol>
            <h2>Vertex Unit Test Results</h2>
          </CCol>
          <CCol class="text-right">
            <CSelect
              :options="showChoices"
              :value.sync="showChoice"
              label="Show Tests:"
              horizontal
            />
          </CCol>
          <CCol class="text-right">
            <DownloadLink
              title="Download"
              :url="`/api/vtx/clients/${clientCode}/test-results/${testRunId}/download`"
              class="btn btn-primary"
            >
              <MIcon name="fa-download" />&nbsp; Export
            </DownloadLink>
          </CCol>
        </CRow>
        <CRow v-if="testResultSummary.total > 0">
          <CCol>
            <CCallout
              color="info"
              :class="{ 'de-selected': showChoice !== 'ALL' }"
              class="pointer"
              @click.native="showChoice = 'ALL'"
            >
              <small class="h5">Total</small>
              <br />
              <strong class="h4">{{ testResultSummary.total }}</strong>
            </CCallout>
          </CCol>
          <CCol>
            <CCallout
              color="success"
              :class="{
                'de-selected': showChoice !== 'ALL' && showChoice !== 'VALID',
              }"
              class="pointer"
              @click.native="showChoice = 'VALID'"
            >
              <small class="h5">Passed</small>
              <br />
              <strong class="h4">{{ testResultSummary.passed }}</strong>
              <span
                v-if="testResultSummary.passedPct > 0"
                class="h5 text-muted pl-2"
                >({{ $format.percent(testResultSummary.passedPct) }})</span
              >
            </CCallout>
          </CCol>
          <CCol>
            <CCallout
              color="warning"
              :class="{
                'de-selected': showChoice !== 'ALL' && showChoice !== 'INVALID',
              }"
              class="pointer"
              @click.native="showChoice = 'INVALID'"
            >
              <small class="h5">Failed</small>
              <br />
              <strong class="h4">{{ testResultSummary.invalid }}</strong>
              <span
                v-if="testResultSummary.invalidPct > 0"
                class="h5 text-muted pl-2"
                >({{ $format.percent(testResultSummary.invalidPct) }})</span
              >
            </CCallout>
          </CCol>
          <CCol>
            <CCallout
              color="danger"
              :class="{
                'de-selected': showChoice !== 'ALL' && showChoice !== 'FAILED',
              }"
              class="pointer"
              @click.native="showChoice = 'FAILED'"
            >
              <small class="h5">Errors</small>
              <br />
              <strong class="h4">{{ testResultSummary.errors }}</strong>
              <span
                v-if="testResultSummary.errorPct > 0"
                class="h5 text-muted pl-2"
                >({{ $format.percent(testResultSummary.errorPct) }})</span
              >
            </CCallout>
          </CCol>
          <CCol class="text-right" col="8">
            <CButtonToolbar>
              <CButton @click="exportResults">Export</CButton>
            </CButtonToolbar>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :items="currentItems"
          :fields="currentFields"
          :loading="searchTestsActive"
          striped
          pagination
          size="sm"
          :items-per-page="50"
          sorter
          column-filter
          table-filter
        >
          <template #show_details="{ item, index }">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="toggleDetails(item, index)"
                >{{ Boolean(item._toggled) ? 'Hide' : 'Show' }}</CButton
              >
            </td>
          </template>
          <template #details="{ item }">
            <CCollapse
              :show="Boolean(item._toggled)"
              :duration="collapseDuration"
            >
              <CRow>
                <CCol>
                  <div style="display: flex">
                    <div>
                      <CButton color="info" @click="handleShowInfo(item)">
                        <MIcon name="fa fa-search-plus" />
                      </CButton>
                    </div>
                    <div>
                      <ul>
                        <li v-for="msg in item.messages">{{ msg }}</li>
                      </ul>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CCollapse>
          </template>
          <template #status="{ item }">
            <td>
              <span
                :class="
                  getStatusObject(item).variant
                    ? 'text-' + getStatusObject(item).variant
                    : ''
                "
              >
                <MIcon :name="getStatusObject(item).status" />
              </span>
            </td>
          </template>
          <template #clientCode="{ item }">
            <td>
              <router-link
                :to="{ name: 'view-client', params: { name: item.clientCode } }"
                >{{ item.clientCode }}</router-link
              >
            </td>
          </template>
          <template #title="{ item }">
            <td>
              <router-link
                v-if="item.taskId"
                :to="{ name: 'view-task', params: { id: item.taskId } }"
                >{{ item.title }}</router-link
              >
              <div v-else>{{ item.title }}</div>
            </td>
          </template>
          <template #recordStatus="{ item }">
            <td class="text-center">
              <CBadge :color="getStatusColor(item)">{{
                getStatusLabel(item)
              }}</CBadge>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
    <TestResultModal
      v-model="testResultModal"
      :test-result="currentTestResult"
    />
  </div>
</template>
<style lang="scss">
.pointer {
  cursor: pointer;
}
.de-selected {
  opacity: 0.5;
}
</style>
<script>
import { mapGetters } from 'vuex';
import CURRENT_FIELDS from '@/../static/data/test-result-fields.json';
import TestResultModal from '@/views/tasks/components/TestResultModal';

const SHOW_CHOICES = [
  {
    label: 'All Results',
    value: 'ALL',
  },
  {
    label: 'Only Valid',
    value: 'VALID',
  },
  {
    label: 'Only Failed',
    value: 'INVALID',
  },
  {
    label: 'Only Errors',
    value: 'FAILED',
  },
];

export default {
  name: 'TestResultsData',
  components: {
    TestResultModal,
  },
  props: {
    clientCode: {
      type: String,
      required: true,
    },
    testRunId: {
      teyp: [Number, String],
      required: true,
    },
  },
  computed: {
    ...mapGetters('tests', [
      'testResults',
      'testResultColumns',
      'searchTestsActive',
      'testResultSummary',
    ]),
    tests() {
      return this.testResults;
    },
    activeFields() {
      return this.testResultColumns;
    },
    currentItems() {
      return JSON.parse(JSON.stringify(this.tests))
        .filter((test) => {
          if (this.showChoice === 'ALL') return true;
          return this.showChoice === test.recordStatus;
        })
        .map((i) => {
          this.currentFields.forEach((f) => {
            if (!this._.has(i, f.key)) {
              i[f.key] = '';
            }
          });

          // this be a hack
          if (this._.has(i, 'DOC NUM')) {
            i['DOC NUM'] = i['DOC NUM'].substring(0, 8) + '...';
          }
          i._toggled = false;
          if (!this._.has(i, 'details')) {
            i._detailsLoading = false;
            i.details = null;
          }
          return i;
        });
    },
    currentFields() {
      let fields = JSON.parse(JSON.stringify(CURRENT_FIELDS));
      fields.forEach((field) => {
        if (this.activeFields.find((f) => f === field.key)) {
          field.visible = true;
        }
      });
      var result = fields.filter((f) => f.visible === true);

      result.unshift({
        label: 'RESULT',
        key: 'recordStatus',
        filter: false,
        visible: true,
      });

      result.unshift({
        label: '',
        key: 'show_details',
        filter: false,
        visible: true,
      });

      return result;
    },
    showChoices() {
      return SHOW_CHOICES;
    },
  },
  mounted() {
    if (this.task) {
      this.$store.dispatch('tests/fetchTestResults', {
        clientCode: this.task.clientCode,
        testRunId: this.task.inputData.testRunId,
      });
    }
  },
  methods: {
    exportResults() {
      this.$store.dispatch('tests/exportTestResults', this.task.testRunId);
    },
    getStatusColor(item) {
      switch (item.recordStatus) {
        case 'VALID':
          return 'success';
        case 'INVALID':
          return 'warning';
        case 'FAILED':
          return 'danger';
        default:
          return 'info';
      }
    },
    getStatusLabel(item) {
      switch (item.recordStatus) {
        case 'VALID':
          return 'Valid';
        case 'INVALID':
          return 'Failed';
        case 'FAILED':
          return 'Error';
        default:
          return item.recordStatus;
      }
    },
    toggleDetails(item) {
      //this.$set(this.currentItems[item.id], '_toggled', !item._toggled);
      item._toggled = !item._toggled;
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    handleShowInfo(item) {
      this.currentTestResult = item.resultId;
      this.testResultModal = true;
    },
  },
  data() {
    return {
      showChoice: 'ALL',
      collapseDuration: 0,
      currentTestResult: null,
      testResultModal: false,
    };
  },
};
</script>
