<template>
  <TestResultsData :client-code="clientCode" :test-run-id="testRunId" />
</template>
<script>
import { mapGetters } from 'vuex';
import TestResultsData from '@/views/tests/components/TestResultsData';

export default {
  name: 'TheTestResultsReview',
  components: {
    TestResultsData,
  },
  props: {
    testRunId: {
      type: [Number, String],
      required: false,
    },
    clientCode: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters('tests', ['testResults']),
  },
  watch: {
    $route(to, from) {
      if (to.params.testRunId && to.params.clientCode) {
        let clientCode = this.clientCode;
        let testRunId = this.testRunId;
        this.$store.dispatch('tests/fetchTestResults', {
          clientCode,
          testRunId,
        });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.params.testRunId && to.params.clientCode) {
        let clientCode = vm.clientCode;
        let testRunId = vm.testRunId;
        vm.$store.dispatch('tests/fetchTestResults', { clientCode, testRunId });
      }
    });
  },
};
</script>
